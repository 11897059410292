#task-bar {
    background: #c0c0c0;
    height: 2.5rem;
    position: absolute;
    bottom: 0;
    width: calc(100% - 1rem);
    display: flex;
    padding: 0.5rem;
    z-index: 999999998;
    justify-content: space-between;
    box-shadow: 0 -2px #fffdfc, 0 -4px #cce9eb
}

#open-tasks:before {
    content: '';
    width: 2px;
    height: calc((100%) - 4px);
    position: absolute;
    background-color: #f2f2f2;
    box-shadow: 1px 1px 0 #818181;
    left: 0.5rem;
    top: 1px
}

#open-tasks {
    margin: 0 0 0 1rem;
    padding: 4px 0 0 2rem;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 4px 0 0 2rem;
    position: relative;
    top: -1px;
    width: calc(100% - 5rem);
}

#taskbar-item {
    height: calc(100% - 4px);  
    display: flex;
    width: 10.5rem;
    align-items: center;
    box-sizing: border-box;
    margin: 0 1rem 0 0;
    font-weight: 600;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
}

#taskbar-item > span > img {
    width: 1.75rem;
    padding: 0 0.5rem 0 0;
}

#taskbar-item > span {
    display: flex;
    overflow: hidden;
    min-width: 2rem;
    text-overflow: ellipsis;
}