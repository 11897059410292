.blog-body {
    background: white;
    overflow-y: auto;
    overflow-x: auto;
    border: 2px inset;
    margin: 1rem 1rem 1rem 1rem;
    padding: 0 1rem 1rem 1rem;
    max-height: 100%;
}

.blog-body img {
    max-width: 100%;
}