.desktop-shortcut {
    color: white;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 7rem;
    height: 6.25rem;
    border: 1px solid transparent;
    min-height: 6.25rem;
    max-height: 6.25rem;
    padding: 0.25rem 0;
    z-index: 2;
    justify-content: center;
    display: flex;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.75rem;
}

.icon > img {
    object-fit: contain;
    width: 3rem;
    margin: 0.75rem 0 0 0;
    overflow: clip;
    overflow-clip-margin: content-box;
    justify-content: center;
    align-self: center;
}

.text {
    display: inline;
    margin: 0.5rem 0;
    text-align: center;
    overflow-wrap: break-word;
}