#start-menu {
    bottom: 3.75rem;
    position: absolute;
    background: #c0c0c0;
    left: 0.25rem;
    width: 24rem;
    min-height: 3rem;
    display: none;
    z-index: 999999999;
}

#start-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#start-menu ul li label {
    height: 4rem;
    float: left;
    padding: 0 0.5rem 0 0.5rem;
    margin: 0 2.5rem;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 3rem);
}

#windows-start-menu-blue {
    color: white;
    font-weight: 900;
    position: absolute;
    transform: rotateZ(-90deg);
    width: 24rem;
    /* Note - width is 4rem X number of apps */
    font-size: 1.25rem;
    font-family: "Arial Black", sans-serif;
    background: navy;
    left: 0;
    transform-origin: 0 0;
    top: 24rem;
    box-sizing: border-box;
    padding: 0.125rem 0.75rem;
}

#windos-start-menu-blue span {
    font-weight: 100;
    font-family: "Arial", sans-serif;
}

.line {
    box-shadow: 0 2px #808280, 0 4px white;
}

#start-menu ul > li > label > img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem 0 0;
}

#start-menu ul > li > label:first-of-type:before {
    width: 14px;
}

#start-menu ul > li > label:before {
    width: 10px;
    background: black;
    content: '';
    height: 1px;
    position: absolute;
    bottom: 20px;
    left: 3rem;
}

#start-menu ul > li > label > span {
    font-size: 14px;
}

#start-menu ul li label:hover {
    background: #0c1b98;
    color: white;
    box-shadow: -8px 0 #0c1b98, 8px 0 #0c1b98;
}

#start-menu > ul > li label:hover:before {
    background: white;
}

.line {
    box-shadow: 0 2px #808280, 0 4px white;
}