.minesweeper {
    width: 100%;
}

.minesweeper-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
}

.minesweeper-clock {
    flex: 1;
    text-align: right;
    flex: 1;
    box-sizing: border-box;
    max-width: fit-content;
}

.minesweeper-smiley-container {
    flex: 1;
    text-align: center;
}

.minesweeper-smile-img {
    flex: 1;
    vertical-align: middle; 
}

.minesweeper-smiley-container > img {
    vertical-align: middle;
    position: relative;
}

.minesweeper-flag-count {
    text-align: left;
    background-color: black;
    inset: 2px;
    display: inline-block;
    border: 3px inset;
    flex: 1;
    box-sizing: border-box;
    max-width: fit-content;
}

.minesweeper-row {
    display: block;
}

.minesweeper-square {
    display: inline-flex;
    background: #c0c0c0;
    width: 1.25rem;
    height: 1.25rem;
    font-family: "Arial Black", sans-serif;
    box-sizing: border-box;
    font-size: 0.75rem;
    position: relative;
    color: black;
    line-height: 1rem;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: 0 6px 0 0;
}

.revealed-square {
    border: 2px dotted black;
    display: inline-flex;
    background: #c0c0c0;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.75rem;
    position: relative;
}

.minesweeper-grid {
    margin: 1rem;
    border: 3px inset;
    box-sizing: border-box;
    padding: 4px;
    width: max-content;
}

.colorBlue {
    color: blue;
}

.colorGreen {
    color: green;
}

.colorRed {
    color: red;
}

.dotted-border {
    border-right: 2px dotted black;
    border-bottom: 2px dotted black;
}