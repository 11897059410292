.windows-box-shadow {
    box-shadow: -2px -2px #e0dede, -2px 0 #e0dede, 0 -2px #e0dede, -4px -4px white, -4px 0 white, 0 -4px white, 2px 2px #818181, 0 2px #818181, 2px 0 #818181, 2px -2px #e0dede, -2px 2px #818181, -4px 2px white, -4px 4px black, 4px 4px black, 4px 0 black, 0 4px black, 2px -4px white, 4px -4px black;
}

.inverted-windows-box-shadow {
    box-shadow: -2px -2px #818181, -2px 0 #818181, 0 -2px #818181, -4px -4px black, -4px 0 black, 0 -4px black, 2px 2px #e0dede, 0 2px #e0dede, 2px 0 #e0dede, 2px -2px #818181, -2px 2px #e0dede, -4px 2px black, -4px 4px white, 4px 4px white, 4px 0 white, 0 4px white, 2px -4px black, 4px -4px white;
}

#root {
    height: 100%;
}

body {
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      font-weight: 300;
      user-select: none;
}

html, body {
    height: 100%;
    margin: 0;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.windows-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 3.75rem);
    overflow: hidden;
}

.task-bar {
    background: #c0c0c0;
    height: 2.5rem;
    position: absolute;
    bottom: 0;
    width: calc(100% - 1rem);
    display: flex;
    padding: 0.5rem;
    z-index: 999999998;
    justify-content: space-between;
    box-shadow: 0 -2px #fffdfc, 0 -4px #cce9eb
}

.programs-view {
    position: absolute;
    width: 100%;
    height: 100%;
}