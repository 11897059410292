.desktop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #008080;
    margin: 0;
    padding: 0.5rem;
    font-family: Arial, sans-serif;
    font-weight: 300;
    user-select: none;
    z-index: 1;
    box-sizing: border-box;
}
