
.window {
    position: absolute;
    box-shadow: -2px -2px #e0dede, -2px 0 #e0dede, 0 -2px #e0dede, -4px -4px white, -4px 0 white, 0 -4px white, 2px 2px #818181, 0 2px #818181, 2px 0 #818181, 2px -2px #e0dede, -2px 2px #818181, -4px 2px white, -4px 4px black, 4px 4px black, 4px 0 black, 0 4px black, 2px -4px white, 4px -4px black;
    overflow: hidden;
    user-select: none;
    resize: both;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: calc(100% - 10px);
    max-width: calc(100% - 10px);
}

.title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #000b7c, #377cc6);
    color: white;
    padding: 0.25rem;
}

.window-title {
    flex: 1;
}

.controls {
    display: flex;
}

.controls > div {
    background: #c0c0c0;
    width: 1rem;
    height: 1rem;
    font-family: "Arial Black", sans-serif;
    box-sizing: border-box;
    font-size: 0.75rem;
    position: relative;
    color: black;
    line-height: 1rem;
    margin: 0 0 0 0.55rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.window-body {
    display: flex;
    background: #b9b9b9;
    flex: 1;
    max-height: calc(100% - 1.5rem);
    /*width: 100%;*/
}

.selected {

}